'use strict';

(function ($, window, undefined) {
  var // Window Document
  // Window Document
  $window = $(window),
      $document = $(document),
      // Window size
  BrowserH = function BrowserH() {
    return $window.height();
  },
      BrowserW = function BrowserW() {
    return $window.width();
  },
      // Scroll top
  ScrollTop = function ScrollTop() {
    return $window.scrollTop();
  },
      // ユーザーエージェント
  userAgent = window.navigator.userAgent.toLowerCase(); // function init


  $document.ready(function () {
    // ユーザーエージェント実行
    if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
      $('body').addClass('ie'); // IE
    } else if (userAgent.indexOf('edge') != -1) {
      $('body').addClass('edge'); // edge
    } else if (userAgent.indexOf('chrome') != -1) {
      $('body').addClass('chrome'); // Chrome
    } else if (userAgent.indexOf('safari') != -1) {
      $('body').addClass('safari'); // Safari
    } else if (userAgent.indexOf('firefox') != -1) {
      $('body').addClass('firefox'); // Firefox
    } // 変数一覧
    // const $hamburgerBtn = $('#js-hamburger-btn'), // ハンバーガーボタン
    //   $navigation = $('#js-navigation'), // ナビゲーション
    //   $pagetopBtn = $('#js-pagetop-btn'); // ページトップボタン
    // スムーズスクロール


    $('a[href^="#"]').click(function () {
      var href = $(this).attr("href"),
          target = $(href === "#" || href === "" ? 'html' : href);
      target.stop().velocity("scroll", {
        duration: 1200,
        easing: "ease"
      }); // $hamburgerBtn.removeClass("is-active");
      // $navigation.removeClass("is-open");

      return false;
    }); // SPメニュー開閉
    // $hamburgerBtn.click(() => {
    //   if (!$hamburgerBtn.hasClass("is-active")) {
    //     $hamburgerBtn.addClass("is-active");
    //     $navigation.addClass("is-open");
    //   } else {
    //     $hamburgerBtn.removeClass("is-active");
    //     $navigation.removeClass("is-open");
    //   }
    // });
    // // ページトップボタンのフェードインフェードアウト
    // $window.scroll(() => {
    //   if (ScrollTop() > 500) {
    //     $pagetopBtn.addClass("is-active");
    //   } else {
    //     $pagetopBtn.removeClass("is-active");
    //   }
    // });
  }); // ready function end
})(jQuery, window);